/* Сброс глобальных стилей для всех элементов */
* {
   margin: 0 ; /* Удалить внешние отступы */
   box-sizing: border-box;
}
body {
  overflow-y:hidden;  
}

/* Специфичные стили для header */
.header {
  padding: 0; /* Удалить внутренние отступы */
  background-color: black;
}

.header-content {
  text-align: center;
  padding: 10px 0; /* Добавить внутренний отступ */
  color: white;
}

/* Стили для основного содержимого */
.content {
 
   margin-left: 8px;
   margin-right: 8px;
     
}
